<ng-container>
  <div class="subscription-banner">
    <div class="centered">{{ ProductName }}</div>
  </div>

  <div class="container mt-4">
    <div class="heading">
      <p class="heading-name">{{ msglabel.default.subscription }}</p>
    </div>
  </div>
  <div id="cards-background">
    
  </div>
</ng-container>
