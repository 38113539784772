<article class="container custom-margin">
  <h1 class="h1" style="font-size: 2rem">Terms &amp; Conditions</h1>

  <div class="entry-content">
    <p>
      <strong>{{ msglabel.default.nCircleTechPrivateLimitedCompany }}</strong>
    </p>

    <h3>
      <em><strong>Acceptance of Terms</strong></em>
    </h3>

    <p>
      By accessing or using one or more of nCircle Tech Private Limited
      (“nCircle”) products as described in clause 2 below, you agree to comply
      with and be bound by these Terms and Conditions for use of nCircle Product
      ("Terms").
    </p>
    <h3>
      <em><strong>Product Description</strong></em>
    </h3>
    <ul>
      <li>Deviation nSpector</li>
      <li>Point Cloud Importer</li>
      <li>BIM Model Checker</li>
      <li>nBIM</li>
      ("Product")
    </ul>

    <h3>
      <em><strong>User Obligations</strong></em>
    </h3>
    <p>You agree to:</p>
    <ol type="i">
      <li>
        Use the Product only for lawful purposes and in accordance with these
        Terms.
      </li>
      <li>
        Maintain the confidentiality of your account information and be
        responsible for all activities that occur under your account.
      </li>
      <li>
        Not use the Product to infringe on any third-party intellectual property
        rights.
      </li>
    </ol>
    <h3>
      <em><strong>No Refund Policy</strong></em>
    </h3>
    <p>
      All fees and charges paid in connection with the Product are
      non-refundable. This includes, but is not limited to, subscription fees,
      usage fees, and any other charges billed to your account.
    </p>
    <h3>
      <em><strong>Intellectual Property Rights</strong></em>
    </h3>
    <p>
      All content, trademarks, service marks, logos, and other intellectual
      property rights in the Product are owned by nCircle or its licensors. You
      are granted a non-exclusive, non-transferable, revocable license to use
      the Product strictly in accordance with these Terms. Any actual or
      suspected violation of this clause 5 or clause 3(iii) above shall entitle
      nCircle to one or more of the following remedies (notwithstanding any
      remedies available under law) - (i) suspension of your usage of the
      Product (ii) seeking injunctive relief against you in the appropriate
      legal forum (iii) a penalty of INR 10,00,000 per breach.
    </p>
    <h3>
      <em><strong>AS IS and AS AVAILABLE Disclaimer</strong></em>
    </h3>

    <p>
      The Product is provided to you "AS IS" and "AS AVAILABLE" and with all
      faults and defects without any warranty of any kind. To the maximum extent
      permitted under applicable law, nCircle on its own behalf and on behalf of
      its affiliates and its and their respective licensors and service
      providers, expressly disclaims all warranties, whether express, implied,
      statutory, or otherwise, with respect to the Product, including all
      implied warranties of merchantability, fitness for a particular purpose,
      title, and non-infringement, and warranties that may arise out of course
      of dealing, course of performance, usage, or trade practice. Without
      limitation to the foregoing, nCircle provides no warranty or undertaking,
      and makes no representation of any kind that the Product will meet your
      requirements, achieve any intended results, be compatible or work with any
      other software, applications, systems, or services, operate without
      interruption, meet any performance or reliability standards, or be
      error-free or that any errors or defects can or will be corrected.
    </p>
    <h3>
      <em><strong>Limitation of Liability</strong></em>
    </h3>
    <ol type="A">
      <li>
        nCircle shall not be liable for any indirect, incidental, special,
        consequential, or punitive damages, or any loss of profits or revenues,
        whether incurred directly or indirectly, or any loss of data, use,
        goodwill, or other intangible losses. The foregoing applies regardless
        of whether nCircle was informed in advance about such potential losses.
      </li>
      <li>
        nCircle shall not be liable for losses resulting from:
        <ol type="i">
          <li>Your use or inability to use the Product;</li>
          <li>
            Any unauthorized access to or use of our servers and/or any personal
            information stored therein;
          </li>
          <li>
            Any interruption or cessation of transmission to or from the
            Product;
          </li>
          <li>
            Any bugs, viruses, trojan horses, or the like that may be
            transmitted to or through the Product by any third party.
          </li>
          <li>
            Any errors or omissions in any content or for any loss or damage
            incurred as a result of the use of any content posted, emailed,
            transmitted, or otherwise made available through the Product.
          </li>
        </ol>
      </li>
      <li>
        nCircle’s total liability to you for any claim arising out of or
        relating to these Terms or your use of the Product shall be limited to
        INR 10,000.
      </li>
      <li>
        You agree to indemnify and hold nCircle harmless from any claims,
        damages, or costs, including attorneys' fees, arising out of your use of
        the Product or your violation of these Terms.
      </li>
    </ol>
    <h3>
      <em><strong>Third-Party Intellectual Property Rights Claims</strong></em>
    </h3>

    <p>
      nCircle shall not be liable for any claims that the Product infringes upon
      any third party's intellectual property rights. You agree to defend,
      indemnify, and hold harmless nCircle from and against any such claims.
    </p>
    <h3>
      <em><strong>Indemnification</strong></em>
    </h3>

    <p>
      You agree to indemnify, defend, and hold harmless nCircle, its affiliates,
      officers, directors, employees, agents, and licensors from and against any
      and all claims, liabilities, damages, losses, costs, expenses, or fees
      (including reasonable attorneys' fees) that they may incur as a result of
      or arising from:
    </p>
    <ul>
      <li>Your use of the Product;</li>
      <li>Your violation of these Terms;</li>
      <li>Your violation of any rights of another party.</li>
    </ul>
    <h3>
      <em><strong>Modifications to the Product and Terms</strong></em>
    </h3>

    <p>
      nCircle reserves the right to modify or discontinue the Product at any
      time without notice. We also reserve the right to change these Terms at
      any time. Any changes to the Terms will be effective immediately upon
      posting. Your continued use of the Product after any such changes
      constitutes your acceptance of the new Terms.
    </p>
    <h3>
      <em><strong>Termination and Suspension of Access</strong></em>
    </h3>

    <p>
      nCircle reserves the right to terminate or suspend your access to the
      Product at any time, for any reason, or for no reason, with or without
      notice. This includes, but is not limited to, situations where we believe
      you have violated these Terms or any applicable law, or where we decide to
      discontinue the Product.
    </p>
    <h3>
      <em><strong>Governing Law</strong></em>
    </h3>

    <p>
      These Terms shall be governed by and construed in accordance with the laws
      of India, without regard to its conflict of law principles.
    </p>
    <h3>
      <em><strong>Dispute Resolution</strong></em>
    </h3>

    <p>
      Any disputes arising out of or in connection with these Terms or the
      Product shall be resolved through binding arbitration conducted in Pune,
      Maharashtra, except that nCircle may seek injunctive or other equitable
      relief in any court of competent jurisdiction to protect its intellectual
      property rights.
    </p>

    <h3>
      <em><strong>Contact Information</strong></em>
    </h3>
    <p>If you have any questions about these Terms, please contact us at:</p>
    <ul>
      <li>
        Address - nCircle Tech Pvt Ltd | Office No.3, IT-7, Qubix SEZ, Blue
        Ridge, Hinjewadi Phase - 1, Pune- 411057, Maharashtra, INDIA
      </li>
      <li>Email Address - support@ncircletech.com</li>
      <li>Phone Number - +91 2066941900</li>
    </ul>
    <p>
      By using the Products, you acknowledge that you have read, understood, and
      agree to be bound by these Terms and Conditions.
    </p>
  </div>
  <!-- .entry-content -->
</article>
