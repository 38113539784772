import { Component, OnInit ,Inject,PLATFORM_ID, Pipe, PipeTransform} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {  SubProductService } from '../../@services/sub-product.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import * as sidebarlabel from '../../../../../assets/i18n/en.json';
import * as sidebarlabel1 from '../../../../../assets/i18n/jp.json';



@Component({
  selector: 'app-sub-product-subscription',
  templateUrl: './sub-product-subscription.component.html',
  styleUrls: ['./sub-product-subscription.component.scss']
})
export class SubProductSubscriptionComponent implements OnInit {

  localstoragelanguage :any;
  msglabel:any;

  monthlyRecurring: number[] = Array.from({ length: 10 }, (_, i) => i + 1);
  yearlyRecurring: number[] = Array.from({ length: 10 }, (_, i) => i + 1);

  monthlyRecurringQuantity: number = 1;
  yearlyRecurringQuantity: number = 1;

 
  countryCurrency : string = sessionStorage.getItem('currency') || "USD";


  
  // recurringAmount : any = () =>{
  //   .keys((item:string)=>{

  //   })
  // }

  APIData : any;

  bannerData:string  = localStorage.getItem('slug')!; 

  cardOneBtn: boolean = true;
  cardTwoBtn: boolean = true;
  cardThreeBtn: boolean = true;

  sessionInfo:any = sessionStorage.getItem('priceId');
  priceId :any = this.sessionInfo && JSON.parse(this.sessionInfo);
  
  ProductTitle:any = sessionStorage.getItem('productTitle');
  ProductName :any = this.sessionInfo && JSON.parse(this.ProductTitle);
  

  constructor(
    private route : Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private SubProductService: SubProductService,
    private http: HttpClient,

  ) { 

    this.SubProductService.getData().subscribe(data => {
      this.APIData=data; 
    });
    
    this.localstoragelanguage = localStorage.getItem('language');

    if (this.localstoragelanguage == "EN") {
      this.msglabel = sidebarlabel

    } else if (this.localstoragelanguage == "IN") {
      this.msglabel = sidebarlabel

    }
    else {
      console.log("message_Label JP : ",this.msglabel);
      this.msglabel = sidebarlabel1
    }

    // code is for if user used VPN only - after refresh check country currency 
    this.getUserCountryByIP().then(currency => {
      sessionStorage.setItem('currency', currency);
    });



  }

  getUserCountryByIP(): Promise<string> {
    return this.http.get('https://ipapi.co/json/')
        .toPromise()
        .then((response: any) => response.currency)
        .catch((error: any) => {
            console.error('Error getting user country by IP:', error);
            return 'Unknown';
        });
  }

  onMonthlyRecurringQtyChange(event:Event) {
    this.monthlyRecurringQuantity = parseInt((event.target as HTMLInputElement).value); 
    console.log(this.monthlyRecurringQuantity ," - this is MONTH QTY")

  }
  onYearlyRecurringQtyChange(event:Event) {
    this.yearlyRecurringQuantity = parseInt((event.target as HTMLInputElement).value); 
    console.log(this.yearlyRecurringQuantity ," - this is YEAR QTY")
  }


  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    console.log("API DATA",  this.priceId["monthly-recurring"].price)

    let isPresent=false;
    
    Object.keys(this.priceId["monthly-recurring"].price).forEach((i:string)=>{
      console.log("#Mehul",i);
      
      if(i===this.countryCurrency) isPresent=true;
    })
    if(!isPresent) this.countryCurrency="USD";
  }

 
  goToCheckOut(id:number){
    let priceID;  
    let Quantity;
    if(id === 1){
      this.cardOneBtn = !this.cardOneBtn;
      priceID = this.priceId["monthly-recurring"].id;
      Quantity = this.monthlyRecurringQuantity;
    }else if(id === 2){
      this.cardTwoBtn = !this.cardTwoBtn;
      priceID = this.priceId["yearly-recurring"].id;
      Quantity = this.yearlyRecurringQuantity;
    }
  
    const requestData =  {
            quantity: Quantity,
            price : priceID  
      };  
  
  
    this.http.post(`${environment.REDIRECTED_URL}/create-checkout-session`,requestData).subscribe(async(res:any)=>{
         try {
          let stripe = await loadStripe(environment.STRIPE_PUBLISHED_KEY);
          stripe?.redirectToCheckout({
            sessionId : res.sessionId
          })
         } catch (error) {
          console.log("checkout error : ",error);
         }  
        })
  }
}


