import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SubProductService } from '../../@services/sub-product.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as sidebarlabel from '../../../../../assets/i18n/en.json';
import * as sidebarlabel1 from '../../../../../assets/i18n/jp.json';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-sub-product-subscription',
  templateUrl: './sub-product-subscription.component.html',
  styleUrls: ['./sub-product-subscription.component.scss'],
  providers: [CurrencyPipe],
})
export class SubProductSubscriptionComponent implements OnInit {
  localstoragelanguage: any;
  msglabel: any;

  monthlyRecurring: number[] = Array.from({ length: 10 }, (_, i) => i + 1);
  yearlyRecurring: number[] = Array.from({ length: 10 }, (_, i) => i + 1);

  monthlyRecurringQuantity: number = 1;
  yearlyRecurringQuantity: number = 1;
  formattedMonthlyPrice: string | undefined;
  formattedYearlyPrice: string | undefined;
  TotalOriginalYearlyAmount: string | undefined;

  countryCurrency: string = sessionStorage.getItem('currency') || 'USD';

  APIData: any;

  // pluginName: string;
  bannerData: string = localStorage.getItem('slug')!;

  cardOneBtn: boolean = true;
  cardTwoBtn: boolean = true;
  cardThreeBtn: boolean = true;
  cardFourBtn: boolean = true;

  sessionInfo: any = sessionStorage.getItem('priceId');
  priceId: any = this.sessionInfo && JSON.parse(this.sessionInfo);


  ProductTitle: any = sessionStorage.getItem('productTitle');
  ProductName: any = this.ProductTitle && JSON.parse(this.ProductTitle);

  constructor(
    private route: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private SubProductService: SubProductService,
    private http: HttpClient,
    private currencyPipe: CurrencyPipe
  ) {
    // this.pluginName = this.formatString(this.bannerData);

    this.SubProductService.getData().subscribe((data) => {
      this.APIData = data;
    });

    this.localstoragelanguage = localStorage.getItem('language');

    if (this.localstoragelanguage == 'EN') {
      this.msglabel = sidebarlabel;
    } else if (this.localstoragelanguage == 'IN') {
      this.msglabel = sidebarlabel;
    } else {
      console.log('message_Label JP : ', this.msglabel);
      this.msglabel = sidebarlabel1;
    }

    // code is for if user used VPN only - after refresh check country currency
    this.getUserCountryByIP().then((currency) => {
      sessionStorage.setItem('currency', currency);
    });
  }

  getUserCountryByIP(): Promise<string> {
    return this.http
      .get('https://ipapi.co/json/')
      .toPromise()
      .then((response: any) => response.currency)
      .catch((error: any) => {
        console.error('Error getting user country by IP:', error);
        return 'Unknown';
      });
  }

  onMonthlyRecurringQtyChange(event: Event) {
    this.monthlyRecurringQuantity = parseInt(
      (event.target as HTMLInputElement).value
    );
    console.log(this.monthlyRecurringQuantity, ' - this is MONTH QTY');
  }

  onYearlyRecurringQtyChange(event: Event) {
    this.yearlyRecurringQuantity = parseInt(
      (event.target as HTMLInputElement).value
    );
    console.log(this.yearlyRecurringQuantity, ' - this is YEAR QTY');
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    if (!['EUR', 'USD', 'INR', 'GBP'].includes(this.countryCurrency)) {
      this.countryCurrency = 'USD';
    }

    const monthlyPrice =
      this.priceId['monthly-recurring'].price[this.countryCurrency];
    this.formattedMonthlyPrice = this.formatPrice(
      monthlyPrice,
      this.countryCurrency
    );

    const yearlyPrice =
      this.priceId['yearly-recurring'].price[this.countryCurrency];
    this.formattedYearlyPrice = this.formatPrice(
      yearlyPrice,
      this.countryCurrency
    );

    this.TotalOriginalYearlyAmount = this.formatTotalYearPrice(
      monthlyPrice * 12,
      this.countryCurrency
    );
    let isPresent = false;

    Object.keys(this.priceId['monthly-recurring'].price).forEach(
      (i: string) => {
        console.log(i, 'monthly-recurring');

        if (i === this.countryCurrency) isPresent = true;
      }
    );
    if (!isPresent) this.countryCurrency = 'USD';
  }

  ngAfterViewInit() {
    document.addEventListener('DOMContentLoaded', function () {
      const focusContainer = document.getElementById('cards-background');
      if (focusContainer) {
        focusContainer.focus();
      }
    });
  }

  private formatPrice(price: number, currencyCode: string): string {
    let formattedValue = this.currencyPipe.transform(
      price,
      currencyCode,
      true,
      '1.2-2'
    );

    // Remove leading zero
    if (formattedValue) {
      formattedValue = formattedValue.replace(/^(\D*)0+(\d)/, '$1$2');
    }

    return formattedValue || '';
  }

  private formatTotalYearPrice(price: number, currencyCode: string): string {
    let formattedValue = this.currencyPipe.transform(
      price,
      currencyCode,
      true,
      '1.2-2'
    );

    // Remove leading zero
    if (formattedValue) {
      formattedValue = formattedValue.replace(/^(\D*)0+(\d)/, '$1$2');
    }

    return formattedValue || '';
  }

  goToCheckOut(id: number) {
    let PlanName;
    let planType;
    let PlanDuration;
    let priceID;
    let Quantity;
    let list1;
    let list2;
    let list3;
    let isListThree;

    console.log(this.priceId);

    if (id === 1) {
      this.cardOneBtn = !this.cardOneBtn;
      PlanName = 'Month';
      planType = 'Onetime';
      PlanDuration = 'month';
      priceID = this.priceId['monthly-recurring'].price[this.countryCurrency];
      Quantity = this.monthlyRecurringQuantity;
      list1 = this.msglabel.default.cardBasiclist1;
      list2 = this.msglabel.default.cardBasiclist2;
      isListThree = false;
    } else if (id === 2) {
      this.cardTwoBtn = !this.cardTwoBtn;
      PlanName = 'Year';
      planType = 'Onetime';
      PlanDuration = 'year';
      priceID = this.priceId['yearly-recurring'].price[this.countryCurrency];
      Quantity = this.yearlyRecurringQuantity;
      list1 = this.msglabel.default.cardProfessionalList1;
      list2 = this.msglabel.default.cardProfessionalList2;
      list3 = this.msglabel.default.cardProfessionalList3;
      isListThree = false;
    } else if (id === 3) {
      this.cardThreeBtn = !this.cardThreeBtn;
      PlanName = 'Professional';
      planType = 'Recurring';
      PlanDuration = 'month';
      priceID = this.priceId['monthly-recurring'].price[this.countryCurrency];
      Quantity = this.monthlyRecurringQuantity;
      list1 = this.msglabel.default.cardSubscriptionlist1;
      list2 = this.msglabel.default.cardSubscriptionlist2;
      isListThree = false;
    }

    const selectedCardDetails: any = {
      Name: PlanName,
      Period: PlanDuration,
      quantity: Quantity,
      price: priceID,
      listOne: list1,
      listTwo: list2,
      listThree: list3,
      listThreeVisible: isListThree,
      type: planType,
    };
    this.route.navigate(['/products-store/subscription/checkout']);
    // if(this.countryCurrency === "INR"){
    //   this.route.navigate(['/contact-us']);
    // }else{
    //   this.route.navigate(['/products-store/subscription/checkout']);
    // }

    
    sessionStorage.setItem('cardDetails', JSON.stringify(selectedCardDetails));

    // Conditional navigation based on planType
  // if (planType === 'Recurring') {
  //   this.route.navigate(['/contact-us']);
  // } else {
  //   this.route.navigate(['/products-store/subscription/checkout']);
  // }
  }
}
